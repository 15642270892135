.chart-card-header {
  margin-bottom: 20px;
  .card-title-text {
    overflow: hidden;
    font-weight: 400;
    white-space: nowrap;
    width: auto;
    text-overflow: ellipsis;
  }
}
.ohm-chart {
  //
}
